import { navigate } from "gatsby";
import React from "react";
import OnboardingNavbar from "../../../components/onboarding_navbar";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import {
  RealmAppProvider,
  APP_ID,
  OnboardingRedirector,
} from "../../../components/realm_app";
import ProfilePictureComponent from "../../../components/profile_picture_upload";

const ProfilePicturePage = () => {
  const onUploadSuccess = () => {
    navigate("/member/dashboard");
  };

  return (
    <>
      <div className="page">
        <OnboardingNavbar />
        <ProfilePictureComponent onUploadSuccess={onUploadSuccess} />
      </div>
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <ProfilePicturePage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
